<template>
	<v-card v-if="editMode" class="mb-10">
		<v-card-text>
			<v-form ref="form" enctype="multipart/form-data">
				<v-row>
					<!-- Avatar -->
					<v-col cols="12" md="4">
						<v-file-input
							accept="image/png, image/jpeg, image/bmp"
							:placeholder="$t('changeAvatar')"
							prepend-icon="mdi-camera"
							:label="$t('avatar')"
							@change="$emit('preview-image', image)"
							v-model="image"
						>
						</v-file-input>
					</v-col>

					<!-- Name -->
					<v-col cols="12" md="4">
						<v-text-field
							v-model="pet.name"
							:label="$t('name') + '*'"
							:rules="nameRules"
							required
						></v-text-field>
					</v-col>

					<!-- Type -->
					<v-col cols="12" md="4">
						<v-select
							v-model="pet.type"
							:items="types"
							:label="$t('type')"
						></v-select>
					</v-col>
				</v-row>

				<v-row>
					<!-- Sex -->
					<v-col cols="12" md="2">
						<v-select
							v-model="pet.sex"
							:items="sex"
							:label="$t('sex')"
						></v-select>
					</v-col>

					<!-- Breed -->
					<v-col cols="12" md="4">
						<v-text-field
							v-model="pet.breed"
							:label="$t('breed')"
						></v-text-field>
					</v-col>

					<!-- Date -->
					<v-col cols="12" md="4">
						<v-menu
							v-model="dateMenu"
							:close-on-content-click="false"
							:nudge-right="40"
							offset-y
							transition="scale-transition"
							min-width="150px"
						>
							<template v-slot:activator="{ on, attrs }">
								<v-text-field
									v-model="formatedBirthdate"
									:label="$t('birthdate')"
									prepend-icon="mdi-calendar"
									readonly
									v-bind="attrs"
									v-on="on"
								></v-text-field>
							</template>
							<v-date-picker
								v-model="pet.birthdate"
								:locale="this.$i18n.locale || 'bg'"
								:first-day-of-week="1"
								@input="dateMenu = false"
							></v-date-picker>
						</v-menu>
					</v-col>

					<!-- Weight -->
					<v-col cols="12" md="2">
						<v-text-field
							v-model="pet.weight"
							:label="$t('weight')"
							type="text"
						></v-text-field>
					</v-col>
				</v-row>

				<v-row>
					<!-- Chip -->
					<v-col cols="12" md="5">
						<v-text-field
							v-model="pet.chip"
							:label="$t('chip')"
							type="text"
						></v-text-field>
					</v-col>
				</v-row>

				<!-- Loader -->
				<v-row>
					<v-col align="center">
						<v-progress-circular
							v-if="isLoading"
							:width="3"
							color="green"
							indeterminate
							class="text-center"
						></v-progress-circular>
					</v-col>
				</v-row>
			</v-form>
		</v-card-text>

		<v-card-actions>
			<v-spacer></v-spacer>
			<v-btn color="grey" dark @click="close">
				{{ $t('close') }}
			</v-btn>
			<v-btn color="primary" @click="save">
				{{ $t('save') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
	name: 'EditPetForm',

	props: {
		editMode: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
			image: null,
			dateMenu: false,
			nameRules: [v => !!v || this.$i18n.t('requiredField')],
		};
	},
	computed: {
		...mapState('pets', ['pet']),
		types() {
			return [
				this.$i18n.t('dog'),
				this.$i18n.t('cat'),
				this.$i18n.t('hamster'),
				this.$i18n.t('ferret'),
				this.$i18n.t('bird'),
				this.$i18n.t('reptile'),
				this.$i18n.t('other'),
			];
		},
		sex() {
			return [this.$i18n.t('male'), this.$i18n.t('female')];
		},
		formatedBirthdate() {
			if (this.pet.birthdate) {
				return this.formatDate(this.pet.birthdate);
			} else {
				return '';
			}
		},
	},
	methods: {
		...mapActions('pets', ['editPet']),
		formatDate: date => (date = moment(date).format('DD.MM.YYYY')),
		close() {
			this.image = null;
			this.$emit('close');
		},
		async save() {
			this.$refs.form.validate();

			if (!this.$refs.form.validate()) {
				return;
			}

			this.isLoading = true;

			let formData = new FormData();

			formData.append('id', this.pet._id);
			formData.append(`file`, this.image);
			formData.append('name', this.pet.name);
			formData.append('type', this.pet.type);
			formData.append('sex', this.pet.sex);
			formData.append('breed', this.pet.breed);
			formData.append('birthdate', this.pet.birthdate);
			formData.append('weight', this.pet.weight);
			formData.append('chip', this.pet.chip);

			try {
				await this.editPet(formData);

				this.image = null;
				this.$emit('save');
				this.isLoading = false;
			} catch (error) {
				console.error(error);
				this.isLoading = false;
			}
		},
	},
};
</script>

<style lang="scss" scoped></style>
